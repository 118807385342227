import React, { useState } from 'react'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Btn } from '@/components/Btn'
import * as styles from './BasinForm.styles.scss'

const BasinForm = ({ submitLabel, fields, onSuccess, onError, url }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const [submitError, setSubmitError] = useState(false)

  const onSubmit = async (data) => {
    axios
      .post(url, data, {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          onSuccess()
        }
      })
      .catch((err) => {
        setSubmitError('There was an error submitting your request')
        console.error(err)
        onError()
      })
  }

  return (
    <div>
      {url && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div>
            <div>
              {isSubmitting && (
                <div className={styles.message} data-type="sending">
                  Submitting...
                </div>
              )}
              {!!submitError && (
                <div className={styles.message}>{submitError}</div>
              )}
              {Object.keys(errors).map((input) => {
                const errorMessage = errors[input].message

                if (errorMessage) {
                  return (
                    <div className={styles.message} key={`error-${input}`}>
                      ● {errorMessage}
                    </div>
                  )
                }
                return undefined
              })}
            </div>
            <div className={styles.fields}>
              {fields.map((item) => {
                return (
                  <div
                    className={classnames(styles.field, {
                      [styles.fullWidth]: item.type === 'textarea',
                    })}
                    key={item.id}
                  >
                    {item.label && (
                      <label htmlFor={item.id} className={styles.label}>
                        {item.label}
                      </label>
                    )}
                    {(item.type === 'text' ||
                      item.type === 'email' ||
                      item.type === 'tel') && (
                      <input
                        id={item.id}
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        className={styles.textInput}
                        {...register(item.name, {
                          required: item.required
                            ? `${item.label} is required.`
                            : false,
                          pattern:
                            item.type === 'email'
                              ? {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: 'Email address is invalid.',
                                }
                              : undefined,
                        })}
                      />
                    )}
                    {item.type === 'textarea' && (
                      <textarea
                        id={item.id}
                        name={item.name}
                        placeholder={item.placeholder}
                        className={styles.textarea}
                        {...register(item.name, {
                          required: item.required
                            ? `${item.label} is required.`
                            : false,
                        })}
                      />
                    )}
                    {item.type === 'select' && !!item.options?.length && (
                      <select
                        id={item.id}
                        name={item.name}
                        className={styles.select}
                        {...register(item.name, {
                          required: item.required
                            ? `${item.label} is required.`
                            : false,
                        })}
                      >
                        {item.options.map((option) => {
                          return (
                            <option key={option.id} value={option.value}>
                              {option.title}
                            </option>
                          )
                        })}
                      </select>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={styles.footer}>
              <Btn type="submit">{submitLabel}</Btn>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

BasinForm.defaultProps = {
  submitLabel: 'Submit',
  fields: [],
  onSuccess: () => {},
  onError: () => {},
  url: undefined,
}

BasinForm.propTypes = {
  submitLabel: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    })
  ),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  url: PropTypes.string,
}

export { BasinForm }
