import { useEffect } from 'react'
import { gsap } from 'gsap'

export const useHoverMotion = (ref, { hoverState, selectors }) => {
  useEffect(() => {
    const bgHover = ref.current?.querySelector(selectors.bgHover)
    const textHover = ref.current.querySelector(selectors.textHover)

    switch (hoverState) {
      case 'initial':
        gsap.set(bgHover, { y: '75%' })
        gsap.set(textHover, { opacity: 0 })
        break
      case 'over':
        gsap.to(bgHover, { y: '0%', duration: 0.3, ease: 'power2.inOut' })
        gsap.to(textHover, { opacity: 1, duration: 0.3, ease: 'power2.inOut' })
        break
      case 'out':
        gsap.to(bgHover, { y: '75%', duration: 0.3, ease: 'power2.out' })
        gsap.to(textHover, { opacity: 0, duration: 0.3, ease: 'power2.inOut' })
        break
      default:
        break
    }
  }, [ref, hoverState, selectors])
}
