import React from 'react'
import PropTypes from 'prop-types'

import { FontFaceLoader } from '@/components/FontFaceLoader'
import { useTransitionService } from '@/services/TransitionService'
import { ResizeService } from '@/services/ResizeService'
import { Metadata } from '@/components/Metadata'
import { PageTransition } from '@/components/PageTransition'
import { Header } from '@/components/Header'
import { Footer } from '@/components/Footer'
import { FooterMinimal } from '@/components/FooterMinimal'

import '@/styles/main.scss'
import * as styles from './Layout.styles.scss'

const Layout = ({ site, page, children }) => {
  useTransitionService()

  return (
    <div>
      <Metadata
        title={page.title}
        defaultTitle={
          site.metadata.subtitle
            ? `${site.metadata.title}: ${site.metadata.subtitle}`
            : site.metadata.title
        }
        titleTemplate={`%s — ${site.metadata.title}`}
        favicon={site.favicon}
        description={site.metadata.description}
        image={page.image || site.metadata.image}
        siteUrl={site.metadata.siteUrl}
        path={page.path}
      />
      <ResizeService>
        <FontFaceLoader fonts={['Whyte']}>
          <div className={styles.Layout}>
            <Header {...site.header} />
            <main className={styles.main}>{children}</main>
            {page.hasMinimalFooter ? (
              <FooterMinimal />
            ) : (
              <Footer {...site.footer} />
            )}
          </div>
        </FontFaceLoader>
      </ResizeService>
      <PageTransition />
    </div>
  )
}

Layout.defaultProps = {
  site: {},
  page: {},
}

Layout.propTypes = {
  site: PropTypes.object,
  page: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Layout
