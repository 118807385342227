import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { useHoverMotion } from './Btn.gsap'
import ArrowSvg from '@/assets/arrow.inline.svg'
import * as styles from './Btn.styles.scss'

const Btn = ({
  children,
  href,
  onClick,
  target,
  type,
  isInline,
  isDisabled,
  arrow,
}) => {
  const [hoverState, setHoverState] = useState('initial')
  const ref = useRef()

  const handleMouseOver = () => setHoverState('over')
  const handleMouseOut = () => setHoverState('out')

  useHoverMotion(ref, {
    hoverState,
    selectors: {
      bgHover: `.${styles.bgHover}`,
      textHover: `.${styles.contentHover}`,
    },
  })

  const attributes = {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    onClick: onClick,
    className: classnames(styles.Btn, { [styles.isDisabled]: isDisabled }),
  }

  const renderInner = (
    <span className={styles.inner}>
      <span className={styles.outline} />
      <span className={styles.bg} />
      <span className={styles.bgHover} />
      <span className={styles.content}>
        <ArrowSvg
          className={classnames(styles.arrow, {
            [styles[`${arrow}Arrow`]]: !!arrow,
          })}
        />
        {children && <span className={styles.text}>{children}</span>}
      </span>
      <span className={styles.contentHover} aria-hidden="true">
        <span className={styles.content}>
          <ArrowSvg
            className={classnames(styles.arrow, {
              [styles[`${arrow}Arrow`]]: !!arrow,
            })}
          />
          {children && <span className={styles.textHover}>{children}</span>}
        </span>
      </span>
    </span>
  )

  // if href then return a link
  if (href) {
    return (
      <span ref={ref}>
        <Link to={href} target={target} {...attributes}>
          {renderInner}
        </Link>
      </span>
    )
  }

  if (isInline) {
    return (
      <span ref={ref} {...attributes}>
        {renderInner}
      </span>
    )
  }

  // default to a button element
  return (
    <button
      type={type} // eslint-disable-line
      ref={ref}
      disabled={isDisabled}
      {...attributes}
    >
      {renderInner}
    </button>
  )
}

Btn.defaultProps = {
  onClick: () => {},
  type: 'button',
  arrow: 'right',
}

Btn.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInline: PropTypes.bool,
  arrow: PropTypes.oneOf(['disabled', 'left', 'right']),
}

export { Btn }
