import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BasinForm } from '@/components/BasinForm'
import { RichText } from '@/components/RichText'
import * as styles from './ContactForm.styles.scss'

const ContactForm = ({ fields, submitLabel, endpoint, confirmation }) => {
  const [isSuccessful, setIsSuccessful] = useState(false)
  const handleSuccess = () => setIsSuccessful(true)

  return (
    <div className={styles.ContactForm}>
      <div
        className={styles.confirmation}
        style={{ display: isSuccessful ? 'block' : 'none' }}
      >
        <RichText>{confirmation}</RichText>
      </div>
      {endpoint ? (
        <div style={{ display: isSuccessful ? 'none' : 'block' }}>
          <BasinForm
            url={endpoint}
            fields={fields}
            onSuccess={handleSuccess}
            submitLabel={submitLabel}
          />
        </div>
      ) : (
        <p>Coming soon</p>
      )}
    </div>
  )
}

ContactForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  submitLabel: PropTypes.string,
  endpoint: PropTypes.string,
  confirmation: PropTypes.node,
}

export { ContactForm }
