// extracted by mini-css-extract-plugin
export var footer = "w_bZ";
export var message = "w_b0";
export var fields = "w_b1";
export var field = "w_b2";
export var fullWidth = "w_b3";
export var button = "w_b4";
export var label = "w_b5";
export var textInput = "w_b6";
export var textarea = "w_b7";
export var select = "w_b8";
export var aniSpin = "w_d";