import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import * as styles from './BtnMenu.styles.scss'

const BtnMenu = ({ isActive, onClick }) => {
  const handleOnClick = () => {
    if (typeof onClick === 'function') onClick()
  }

  return (
    <button
      type="button"
      className={classnames(styles.BtnMenu, { [styles.isActive]: isActive })}
      onClick={handleOnClick}
      aria-label={`${isActive ? 'Close' : 'Open'} Menu`}
      aria-expanded={isActive}
      aria-controls="menu" // references a menu id attribute
    >
      <div className={styles.menu}>
        <div className={styles.menu__line} />
        <div className={styles.menu__line} />
        <div className={styles.menu__line} />
      </div>
      <div className={styles.close}>
        <div className={styles.close__line} />
        <div className={styles.close__line} />
      </div>
    </button>
  )
}

BtnMenu.defaultProps = {
  isActive: false,
  onClick: () => {},
}

BtnMenu.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

export { BtnMenu }
