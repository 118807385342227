import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getLink } from '@/storyblok/utils/getLink'
import faviconImg from '@/assets/favicon.png'
import { getRichText } from '@/storyblok/utils/getRichText'
// import { getSlug } from '@/utils/getSlug'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        global: allStoryblokEntry(filter: { slug: { eq: "global" } }) {
          edges {
            node {
              id
              full_slug
              content
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = data.gatsbyConfig.siteMetadata || {}
  const storyblokConfig = get(data, 'global.edges')
    ? JSON.parse(data.global.edges[0].node?.content)
    : {}

  const nav = []
  storyblokConfig.header_links.forEach((item) => {
    nav.push(
      getLink({
        id: item._uid,
        title: item.title,
        link: item.link,
      })
    )
  })

  const team = []
  storyblokConfig.about_team.forEach((item) => {
    team.push(
      getLink({
        id: item._uid,
        title: item.title,
        link: item.link,
      })
    )
  })

  const footerNav = []
  storyblokConfig.footer_nav.forEach((item) => {
    footerNav.push(
      getLink({
        id: item._uid,
        title: item.title,
        link: item.link,
      })
    )
  })

  const websiteTitle = storyblokConfig.title || gatsbyConfig.title

  return {
    metadata: {
      title: websiteTitle,
      description: storyblokConfig.description || gatsbyConfig.description,
      subtitle: storyblokConfig.subtitle || gatsbyConfig.subtitle,
      image: get(storyblokConfig, 'image.filename') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    favicon: {
      default: faviconImg,
      darkMode: null,
    },
    header: {
      title: websiteTitle,
      nav,
    },
    footer: {
      about: {
        heading: storyblokConfig.about_heading,
        intro: getRichText(storyblokConfig.about_intro),
        bodyText: getRichText(storyblokConfig.about_body),
        team: team,
      },
      nav: footerNav,
      contact: {
        heading: storyblokConfig.contact_heading,
        formEndpoint: storyblokConfig.contact_form_endpoint,
        confirmation: 'Thank you for getting in touch',
        fields: [
          {
            id: 'name',
            name: 'name',
            type: 'text',
            label: 'First and last name',
            required: true,
          },
          {
            id: 'company',
            name: 'company',
            type: 'text',
            label: 'Company',
          },
          {
            id: 'email',
            name: 'email',
            type: 'email',
            label: 'Email',
            required: true,
          },
          {
            id: 'postcode',
            name: 'postcode',
            type: 'text',
            label: 'Postcode',
          },
          {
            id: 'subject',
            name: 'subject',
            type: 'select',
            label: 'Enquiry Type',
            options: [
              { id: 'general', value: 'general', title: 'General Enquiry' },
              { id: 'commercial', value: 'commercial', title: 'Commercial' },
            ],
          },
          {
            id: 'message',
            name: 'message',
            type: 'textarea',
            label: 'Comments',
          },
        ],
      },
    },
  }
}
