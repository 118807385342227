import React, { useEffect, useState } from 'react'
import FontFaceObserver from 'fontfaceobserver'
import PropTypes from 'prop-types'

const FontFaceLoader = ({ fonts, timeOut, renderLoader, children }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const fontObservers = []
    fonts.forEach((fontFamily) => {
      const observer = new FontFaceObserver(fontFamily)
      fontObservers.push(observer.load(null, timeOut))
    })

    Promise.all(fontObservers).then(
      () => {
        setIsLoaded(true)
      },
      () => {
        console.warn('One or more webfonts failed to load') // eslint-disable-line no-console
        setIsLoaded(true)
      }
    )
  }, [fonts, timeOut])

  return <>{isLoaded ? children : renderLoader}</>
}

FontFaceLoader.defaultProps = {
  fonts: [],
  timeOut: 3000,
}

FontFaceLoader.propTypes = {
  fonts: PropTypes.array,
  timeOut: PropTypes.number,
  renderLoader: PropTypes.node,
  children: PropTypes.node,
}

export { FontFaceLoader }
