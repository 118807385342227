import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'
import { gsap } from 'gsap'
import { navigate } from 'gatsby'
import { globalHistory } from '@reach/router'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { menuState } from '@/store/menuState'
import TransitionService from '@/services/TransitionService'
import { Link } from '@/components/Link'
import { Btn } from '@/components/Btn'
import { BtnMenu } from '@/components/BtnMenu'
import { Container } from '@/components/Container'
import * as styles from './Header.styles.scss'

gsap.registerPlugin(ScrollToPlugin)

const Header = ({ title, nav, contactCta }) => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)

  const handleToggleMenu = () => setIsMenuOpen((prev) => !prev)

  const handleClickNavLink = (event, { url, anchor }) => {
    event.preventDefault()
    const currentPath = globalHistory.location.pathname
    setIsMenuOpen(false)

    if (anchor && currentPath === url) {
      scrollTo(`#${anchor}`)
    } else if (TransitionService.ready && currentPath !== url) {
      TransitionService.in(url)
        .then(() => {
          navigate(url)
        })
        .then(() => {
          if (anchor) {
            setTimeout(() => scrollTo(`#${anchor}`), 1000)
          }
        })
        .catch((error) => console.warn(error))
    }
  }

  const scrollTo = (id) =>
    gsap.to(window, { duration: 1, scrollTo: id, ease: 'power1.inOut' })

  return (
    <header className={styles.Header}>
      <Container>
        <div className={styles.inner}>
          <h1 className={styles.left}>
            <Link to="/" aria-label={title} className={styles.logo}>
              {title}
            </Link>
          </h1>
          <nav
            className={classnames(styles.nav, {
              [styles.isMenuOpen]: isMenuOpen,
            })}
          >
            <ul className={styles.nav__list}>
              {nav.map((item) => (
                <li key={item.id} className={styles.nav__item}>
                  <a
                    href={item.url}
                    target={item.target}
                    download={item.download}
                    className={styles.nav__link}
                    onClick={(event) =>
                      handleClickNavLink(event, {
                        url: item.url,
                        anchor: item.anchor,
                      })
                    }
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className={styles.right}>
            <Btn
              onClick={(event) =>
                handleClickNavLink(event, {
                  url: '/',
                  anchor: 'contact',
                })
              }
            >
              {contactCta}
            </Btn>
            <div className={styles.btnMenu}>
              <BtnMenu onClick={handleToggleMenu} isActive={isMenuOpen} />
            </div>
          </div>
        </div>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  nav: [],
  contactCta: 'Enquire',
}

Header.propTypes = {
  title: PropTypes.string,
  nav: PropTypes.array,
  contactCta: PropTypes.string,
}

export { Header }
