import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { ContactForm } from '@/components/ContactForm'
import { Link } from '@/components/Link'
import { SplitLayout } from '@/components/SplitLayout'
import * as styles from './Footer.styles.scss'

const Footer = ({ about, contact, nav }) => {
  return (
    <footer className={styles.Footer}>
      <SplitLayout
        column01={{
          title: about.heading,
          body: (
            <div id="about">
              <div className={styles.intro}>
                <RichText>{about.intro}</RichText>
              </div>
              <div className={styles.bodyText}>
                <RichText>{about.bodyText}</RichText>
              </div>
              {!!about.team.length && (
                <div className={styles.team}>
                  <div className={styles.team__heading}>The Team</div>
                  <div className={styles.team__list}>
                    {about.team.map((item) => {
                      return (
                        <Link
                          to={item.url}
                          target="_blank"
                          key={item.id}
                          className={styles.team__link}
                        >
                          {item.title}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          ),
        }}
        column02={{
          title: contact.heading,
          body: (
            <div id="contact">
              <ContactForm
                endpoint={contact.formEndpoint}
                fields={contact.fields}
                confirmation={contact.confirmation}
              />
              {!!nav.length && (
                <div className={styles.nav}>
                  {nav.map((item) => {
                    return (
                      <Link
                        to={item.url}
                        target={item.target}
                        key={item.id}
                        className={styles.team__link}
                      >
                        {item.title}
                      </Link>
                    )
                  })}
                </div>
              )}
            </div>
          ),
        }}
      />
    </footer>
  )
}

Footer.defaultProps = {
  nav: [],
}

Footer.propTypes = {
  about: PropTypes.object,
  contact: PropTypes.object,
  nav: PropTypes.arrayOf(PropTypes.object),
}

export { Footer }
