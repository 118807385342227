import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './SplitLayout.styles.scss'

const SplitLayout = ({ column01, column02 }) => {
  return (
    <div className={styles.SplitLayout}>
      {column01 && (
        <div className={styles.col}>
          {column01.title && <h3 className={styles.head}>{column01.title}</h3>}
          <div className={styles.body}>{column01.body}</div>
        </div>
      )}
      {column02 && (
        <div className={styles.col}>
          {column02.title && <h3 className={styles.head}>{column02.title}</h3>}
          <div className={styles.body}>{column02.body}</div>
        </div>
      )}
    </div>
  )
}

SplitLayout.propTypes = {
  column01: PropTypes.object,
  column02: PropTypes.object,
}

export { SplitLayout }
