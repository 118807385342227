import React from 'react'
import { Container } from '@/components/Container'
import { Btn } from '@/components/Btn'
import * as styles from './FooterMinimal.styles.scss'

const FooterMinimal = () => {
  return (
    <footer className={styles.FooterMinimal}>
      <Container>
        <div className={styles.inner}>
          <Btn href="/" arrow="left">
            Home
          </Btn>
        </div>
      </Container>
    </footer>
  )
}

export { FooterMinimal }
