import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import * as styles from './RichText.styles.scss'

const RichText = ({
  children,
  hasHeadingStyles,
  hasListStyles,
  hasImageStyles,
  hasTableStyles,
}) => {
  return (
    <div
      className={classnames(styles.RichText, {
        [styles.headingStyles]: hasHeadingStyles,
        [styles.listStyles]: hasListStyles,
        [styles.imageStyles]: hasImageStyles,
        [styles.tableStyles]: hasTableStyles,
      })}
    >
      {children}
    </div>
  )
}

RichText.defaultProps = {
  hasHeadingStyles: true,
  hasListStyles: true,
  hasImageStyles: true,
  hasTableStyles: true,
}

RichText.propTypes = {
  children: PropTypes.node.isRequired,
  hasHeadingStyles: PropTypes.bool,
  hasListStyles: PropTypes.bool,
  hasImageStyles: PropTypes.bool,
  hasTableStyles: PropTypes.bool,
}

export { RichText }
