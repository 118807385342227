// extracted by mini-css-extract-plugin
export var Footer = "v_bR";
export var intro = "v_bS";
export var bodyText = "v_bT";
export var team = "v_bV";
export var team__heading = "v_bW";
export var team__list = "v_bX";
export var team__link = "v_bY";
export var nav = "v_bL";
export var nav__link = "v_bQ";
export var aniSpin = "v_d";